import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../pages/dashboard/dashboard.module.scss';
import TextField from '@mui/material/TextField';
// import Input from '@mui/material/Input';
import "./css/css.scss";
import AxiosConfig from '../../AxiosConfig';

const HolidayButton = styled(Button)(({ theme }) => ({
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid #000' ,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, minWidth: '200px'}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HolidayFormModal = (props) => {
    const [activeButton, setActiveButton] = useState(props.restCode != null && props.restCode != false ? props.restCode : null);
    const [memo, setMemo] = useState("");
    const isInitialRender = useRef(true); // 初回判定用のフラグ
    const [status, setStatus] = useState();
    const [isAttendanceVisible, setIsAttendanceVisible] = useState(false);
    const [isWork, setIsWork] = useState(false);
    const [works, setWorks] = useState([]);
    const [restCode, setRestCode] = useState();

    const activeBtnEvent = (button) => {
        if (activeButton === button) { 
          setActiveButton(null);
          setRestCode(button)
        } else {
          setActiveButton(button);
          setRestCode(button);
        }
    };

    const [year, month, day] = props.date.split("-");
    const formattedDate = `${month}/${day}`;
    const handleClickClose = (param) => {
      if(param === 'rest') {
        props.onAlertModalClose('rest');
      } else {
        props.onAlertModalClose();
        setActiveButton(null);
        setTimeout(() => {
          if (props.works && props.works.hasOwnProperty('rest_code')) {
            setRestCode(props.works.rest_code);
            setMemo(props.works.memo);
          }
          // setActiveButton(restCode);
        }, 300);
      }
    };

    useEffect(() => {
      if (isInitialRender.current) {
        setMemo(props.memo != null && props.memo != false && props.memo != "" ? props.memo : "");
      }
      setActiveButton(props.restCode);
      setIsAttendanceVisible(props.isAttendanceVisible);
      setIsAttendanceVisible(props.works);
      if (props.works && props.works.hasOwnProperty('is_work')) {
        setIsWork(props.works.is_work);
        setWorks(props.works);
        setMemo(props.memo);
        setActiveButton(props.restCode);
      }
      setRestCode(props.restCode !== false ? props.restCode : props.works.rest_code);

    }, [props.works, props.isAttendanceVisible, props.setRestCode, props.restCode, props.memo]);

    const holidaySave = async() => {

      const users = JSON.parse(localStorage.getItem("users"));

      if(!activeButton) {
        alert('休暇の種類を選択してください。');
        return false;
      }
      if(activeButton === 14  && !memo ) {
        alert('特休の内容を入力してください。');
        return false;
      } else {
          try {
              const data = {
                user_id : !props.userid ? users.id : props.userid,
                date : props.date,
                is_work : 'false',
                rest_code : activeButton && activeButton > 0 ? activeButton : restCode,
                is_morning_rest : 'false',
                is_afternoon_rest : 'false',
                is_late : 'false', 
                is_early : 'false',
                memo : memo,
                update_user_name : users.family_name
              };
              // console.log(data);
              // console.log(restCode);
              let response;

              response = await AxiosConfig.post('/api/update_worktime', data);
            
              if (response.data.res.code === 'success') {
                  // console.log(response.data.res.code);
                  // alert('保存しました!');
                  props.setIsWork(false);
                  props.setMemo(memo);
                  handleClickClose('rest');
              } else {
                  alert(response.data.res.msg);
              }
              
          } catch (error) {
              console.log(error);
              alert('保存失敗しました。');
          }
      }

    };
    // if(!(works === "" ||  works === null || works === undefined)) {
    //   console.log('Holi',works.rest_code);
    // }

    return (
        <div>
            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={props.isOpen}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                    {formattedDate}({props.weekday})休暇情報入力
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <div>
                        <div>
                            <div className="line_title_div">
                                <HolidayButton variant="outlined" size="medium" onClick={() => activeBtnEvent(11)} className={ restCode === 11 || activeButton === 11 ? "active" : ""}>有給</HolidayButton>
                            </div>
                            <div className="line_title_div">
                                <HolidayButton variant="outlined" size="medium" onClick={() => activeBtnEvent(12)} className={ restCode === 12 || activeButton === 12 ? "active" : ""}>代休</HolidayButton>
                            </div>
                        </div>
                        <div>
                            <div className="line_title_div">
                                <HolidayButton variant="outlined" size="medium" onClick={() => activeBtnEvent(13)} className={ restCode === 13 || activeButton === 13 ? "active" : ""}>欠勤</HolidayButton>
                            </div>
                            <div className="line_title_div" style={{flexDirection: 'column'}}>
                                <HolidayButton variant="outlined" size="medium" onClick={() => activeBtnEvent(14)} className={ restCode === 14 || activeButton === 14 ? "active" : ""} style={{alignSelf: 'center'}}>特休</HolidayButton>
                                <small style={{width: '75%', alignSelf: 'center', textAlign: 'centerz'}}>※慶弔休暇、夏期休暇、その他特例で会社が休みとした場合に選択してください。</small>
                            </div>
                        </div>
                    </div>
                    <div className={styles.main_div}>
                        <p style={{ textAlign: 'right', color: '#ff0000', fontSize: '12px', margin: '0'}}>※特休の場合必須</p>
                        <TextField
                          id="outlined-multiline-static"
                          label="備考"
                          value={memo}
                          multiline
                          rows={2}
                          style={{ width : '100%' }}
                          onChange={(e) => setMemo(e.target.value)}
                        />
                    </div>
                    <div className="submitBtnArea" style={{ display: 'flex', 'justifyContent': 'center' }}>
                        <Button variant="outlined" onClick={holidaySave} >登録</Button>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

// export default AlertModal;

export default React.memo(HolidayFormModal);