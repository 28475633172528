import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { useState, useEffect, useRef } from 'react';
import AxiosConfig from '../../AxiosConfig';
import Link from '@mui/material/Link';


// const summaries = ["出勤日数(日)", "合計労働時間(H)", "所定労働未達時間(H)", "時間外労働(H)", "深夜労働(H)", "法定休日労働(H)", "遅刻日数(日)", "早退日数(日)", "欠勤日数(日)", "休出日数(日)", "有給日数(日)", "特休日数(日)", "有給残日数(日)", "有給付与日", "前回有給付与日数", "社員情報編集","備考"]
const summaries = ["出勤日数", "合計労働<br />時間", "所定労働<br />未達時間", "時間外労<br />働", "深夜労働", "法定休日<br />労働", "遅刻日数", "早退日数", "欠勤日数", "休出日数", "有給日数", "特休日数", "有給残日<br />数", "有給付与<br />日", "前回有給<br />付与日数", "社員情報<br />編集","備考"]

const myCallback = (run) => {
    return run();
};

const BasicTable = (props) => {

  // const [year, setYear] = useState(props.year);
  // const [month, setMonth] = useState(props.month);
  const [users, setUsers] = useState();
  const [outMemos, setOutMemos] = useState({});

  useEffect(() => {
    if (props.users) {
      setUsers(props.users);
      const initialMemos = {};
      props.users.forEach(user => {
        initialMemos[user.id] = user.memo || ''; 
      });
      setOutMemos(initialMemos);
    }
  }, [props.users]);


  useEffect(() => {
    if(localStorage.users) {
      setUsers(JSON.parse(localStorage.users));
    }
  }, [props.year, props.month, props.setIsSubmit, outMemos]);

  const submitMonthWork = async(param) => {

    const data = {
      'user_id' : param,
      'date' : props.year+'-'+props.month.toString().padStart(2, '0'),
      'is_submit': true,
      'create_user_name': users.family_name,
    }
    // console.log('data!!!', data);

    // if (window.confirm("編集不可にしますか？")) {
    // }

      props.setStat(true);

      try {
        const response = await AxiosConfig.post('/api/submitSummary', data);

        if (response.status === 200) {
          // if(response.data.code === 'success') {
          //   // alert(response.data.res.msg);
          // } else {
          //   // alert(response.data.res.msg);
          // }
            // console.log(response);
        } else {
          alert('登録失敗しました。');
        }
      } catch (error) {
        console.log(error);
        alert('登録失敗しました。');
      }
      props.setIsSubmit(false);
      props.setIsSubmit(true);
      props.setStat(false);

  }
  const cancelMonthWork = async(param) => {

    const data = {
      'user_id' : param,
      'date' : props.year+'-'+props.month.toString().padStart(2, '0'),
    }
    // console.log('data!!22!', data);

    if (window.confirm("編集可能に戻しますか？")) {
      props.setStat(true);

      try {
          const response = await AxiosConfig.post('/api/cancelSummary', data);

          if (response.status === 200) {
            // if(response.data.code === 'success') {
            //   // alert(response.data.msg);
            // } else {
            //   alert(response.data.msg);
            // }
            // // console.log(response);
          } else {
              alert('削除失敗しました。');
          }
      } catch (error) {
          console.log(error);
          alert('削除失敗しました。');
      }
      props.setIsSubmit(false);
      props.setIsSubmit(true);
      props.setStat(false);
    }

  }
  const adjustHeight = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  const changeTextBox = async (id, value, name) => {
    setOutMemos((prevState) => ({
      ...prevState,
      [id]: value
    }));
  
    const data = {
      'outMemos': value,
      'date': `${props.year}-${props.month.toString().padStart(2, '0')}`,
      'id': id,
      'user_name': name,
      'create_user_name': users.family_name,
    };
  
    try {
      const response = await AxiosConfig.post('/api/create_user_memo', data);
  
      if (response.status === 200) {
      } else {
        alert('登録失敗しました。');
      }
    } catch (error) {
      console.log(error);
      alert('登録失敗しました。');
    }
  };


  return (
    <TableContainer component={Paper} style={{ overflow: 'auto' }}>
      <Table sx={{tableLayout: 'fixed', minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '16px 7px', width:'40px' }}></TableCell>
            <TableCell style={{ padding: '16px 7px', width:'100px' }}></TableCell>
            {myCallback(() => {
                const list = [];
                for (const [i, product] of summaries.entries()) {
                  const parts = product.split('<br />');
                  list.push(
                    <TableCell align="center" key={i} style={{ padding: '16px 7px' ,width: i !== 16 ? '50px' : '270px'}}>
                      {parts.map((part, index) => (
                        <React.Fragment key={index}>
                          {part}
                          {index < parts.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </TableCell>
                  );
                }
                return list;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
            {

              props.users.map((user) => {

                // const dateObj = new Date(user.join_date);
                // const formattedDate = `${dateObj.getMonth() + 1}月1日`;

                const dateObj = new Date(user.join_date);
                let month = dateObj.getMonth() + 1; // 当月
                let day = dateObj.getDate(); // 当日
                month += 6; // 6ヶ月後
                
                // もし月が(month) 13以上なら12をマイナスして1~12間の値にします。
                if (month > 12) {
                    month -= 12;
                }
                
                // const formattedDate = `${month}月1日`;
                const formattedDate = `${month}月${day}日`;

                return (
                  <TableRow
                  //   key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                      <TableCell component="th" scope="row" style={{position: 'sticky', left: '0', backgroundColor: '#f3f6fc'}}>
                        <Link href={`/dashboard?year=${props.year}&month=${props.month}&id=${user.id}`}>{user.family_name}</Link>
                      </TableCell>
                      <TableCell>
                        {
                          user.is_submit === true ? (
                            <Link onClick={() => cancelMonthWork(user.id)} style={{backgroundColor: '#FFE599' , color: '#000', cursor: 'pointer'}}>編集可能に戻す</Link>
                          ) : (
                            <Link onClick={() => submitMonthWork(user.id)} style={{backgroundColor: '#A4C2F4' , color: '#000', cursor: 'pointer'}}>編集不可にする</Link>
                          )
                        }
                      </TableCell>
                      <TableCell align="right">{user.work_days ? user.work_days : '0'}日</TableCell>
                      <TableCell align="right">{(((user.work_times) && user.work_times.split(':')[0] !== '00') || ((user.work_times) && user.work_times.split(':')[1] !== '00')) ? parseInt(user.work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(user.work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</TableCell>
                      <TableCell align="right">{(((user.under_work_times) && user.under_work_times.split(':')[0] !== '00') || ((user.under_work_times) && user.under_work_times.split(':')[1] !== '00')) ? parseInt(user.under_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(user.under_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</TableCell>
                      <TableCell align="right">{(((user.over_work_times) && user.over_work_times.split(':')[0] !== '00') || ((user.over_work_times) && user.over_work_times.split(':')[1] !== '00')) ? parseInt(user.over_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(user.over_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</TableCell>
                      <TableCell align="right">{(((user.night_work_times) && user.night_work_times.split(':')[0] !== '00') || ((user.night_work_times) && user.night_work_times.split(':')[1] !== '00')) ? parseInt(user.night_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(user.night_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</TableCell>
                      <TableCell align="right">{(((user.holiday_work_times) && user.holiday_work_times.split(':')[0] !== '00') || ((user.holiday_work_times) && user.holiday_work_times.split(':')[1] !== '00')) ? parseInt(user.holiday_work_times.split(':')[0], 10).toString().padStart(2, '0') + ":" + parseInt(user.holiday_work_times.split(':')[1], 10).toString().padStart(2, '0') : '0'}</TableCell>
                      <TableCell align="right">{user.late_days ? parseInt(user.late_days, 10) : '0'}日</TableCell>
                      <TableCell align="right">{user.early_days ? parseInt(user.early_days, 10) : '0'}日</TableCell>
                      <TableCell align="right">{user.absence_days ? parseInt(user.absence_days, 10) : '0'}日</TableCell>
                      {/* <TableCell align="right">{user.absence_days ? user.absence_days : '0'}日</TableCell> */}
                      <TableCell align="right">{user.holiday_work_days ? parseInt(user.holiday_work_days, 10) : '0'}日</TableCell>
                      <TableCell align="right">{user.paid_days ? user.paid_days : '0'}日</TableCell>
                      <TableCell align="right">{user.special_holiday ? parseInt(user.special_holiday, 10) : '0'}日</TableCell>
                      <TableCell align="right">{user.paid_value ? user.paid_value : '0'}日</TableCell>
                      <TableCell align="right">{formattedDate}</TableCell>
                      <TableCell align="right">{user.paid_holiday ? user.paid_holiday : '0'}日</TableCell>
                      <TableCell align="center">
                        <Link href={`/join?id=${user.id}`} style={{backgroundColor: '#A4C2F4' , color: '#000', cursor: 'pointer'}}>編集</Link>
                      </TableCell>
                      <TableCell align="right" key={user.id}>
                        <TextField
                          id={`outlined-multiline-static id-${user.id}`}
                          value={outMemos[user.id] || ''} 
                          multiline
                          style={{ 'min-width':'270px', width : '100%', backgroundColor : 'white'}}
                          onChange={(e) => {
                            changeTextBox(user.id, e.target.value);
                            adjustHeight(e);
                          }}
                        />
                    </TableCell>
                  </TableRow>
                )
              })
            }
        </TableBody>
      </Table>
  </TableContainer>
  );
}

export default BasicTable;